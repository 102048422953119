
import Mixins from "@/Mixins.vue";
import { invoiceStore } from "@/store";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { addYears, getYear, isAfter, parse } from "date-fns";
import _ from "lodash";
import { Component, Prop } from "vue-property-decorator";
import { ICreditCard } from "../../interfaces/creditCard";

@Component({
  components: {
    VueQrcode,
  },
})
export default class Cartao extends Mixins {
  @Prop({ type: String, default: "" }) readonly couponCode!: string;
  // @Prop({ required: true, type: String }) readonly value!: string;

  maxiPagoBaseURL = "https://secure.maxipago.net/maxipay/#!/fp?p=";

  loadingPayment = false;

  cartaoForm: ICreditCard = {
    name: "",
    number: "",
    month: "",
    year: "",
    cvv: "",
    installment_number: 1,
  };

  isValid = false;

  get validCreditCardDate() {
    if (this.cartaoForm.month && this.cartaoForm.year) {
      const date: Date = parse(
        `20${this.cartaoForm.year}-${this.cartaoForm.month}-01`,
        "yyyy-MM-dd",
        new Date()
      );

      return isAfter(date, new Date());
    }
  }

  get isTeste() {
    return [17, 1269].includes(this.lastInvoice.company_id);
  }

  get cartaoFields() {
    return [
      {
        name: "Nome completo",
        type: "text",
        placeholder: "Digite seu nome igual está no cartão",
        model: "name",
        rules: [
          (v) => !!v || "Campo obrigatório",
          (v: string) =>
            (!!v && v.split(" ").length > 1) || "Digite seu nome completo",
        ],
        cols: 12,
      },
      {
        name: "Número do cartão",
        type: "text",
        placeholder: "Número do cartão de crédito",
        model: "number",
        mask: "#### #### #### ####?#",
        counter: 20,
        rules: [
          (v) => !!v || "Campo obrigatório",
          (v: string) => (!!v && v.length >= 18) || "Número de cartão inválido",
        ],
        cols: 12,
      },
      {
        name: "Mês",
        type: "select",
        itemText: undefined,
        itemValue: undefined,
        items: _.map(_.range(1, 13), (v) => _.toString(v).padStart(2, "0")),
        placeholder: "Mês",
        model: "month",
        rules: [(v) => !!v || "Campo obrigatório"],
        cols: 3.5,
      },
      {
        name: "Ano",
        type: "select",
        placeholder: "Ano",
        model: "year",
        itemText: undefined,
        itemValue: undefined,
        items: _.map(
          _.range(getYear(new Date()), getYear(addYears(new Date(), 15))),
          _.toString
        ),
        rules: [(v) => (!!v && v.length === 4) || "Campo obrigatório"],
        cols: 4.1,
      },
      {
        name: this.isMobile ? "CVV" : "Cód. Segurança",
        type: "number",
        placeholder: "CVV",
        model: "cvv",
        counter: 5,
        mask: "###?#?#",
        rules: [(v) => (!!v && v.length >= 3) || "Campo obrigatório"],
        cols: 3.8,
      },
      {
        name: "Número de parcelas",
        type: "select",
        items: this.lastInvoice ? this.formasPagamento[1] : [],
        placeholder: "Selecione a condição de pagamento",
        model: "installment_number",
        itemText: "text",
        itemValue: "parcela",
        rules: [(v) => !!v || "Campo obrigatório"],
        cols: 12,
      },
    ];
  }

  get lastInvoice() {
    return invoiceStore.lastInvoice;
  }

  get formasPagamento() {
    return invoiceStore.formasPagamento;
  }

  reset() {
    this.cartaoForm = {
      name: "",
      number: "",
      month: "",
      year: "",
      cvv: "",
      installment_number: 1,
    };
    const cartaoForm: any = this.$refs.cartaoForm;
    if (cartaoForm) {
      cartaoForm.resetValidation();
    }
  }

  async submitPaymentCartao() {
    this.loadingPayment = true;
    const creditCard: ICreditCard = this.cartaoForm;
    creditCard.cobranca_id = this.lastInvoice.id;
    creditCard.number = creditCard.number.replace(/\s/g, "");

    const payload: any = {
      creditCard: creditCard,
    };

    if (this.couponCode.length > 0) {
      payload.couponCode = this.couponCode;
    }

    const { confirmed, data } = await invoiceStore.submitPayment(payload);

    if (confirmed) {
      // Pedido pago
      this.cartaoForm = {
        name: "",
        number: "",
        month: "",
        year: "",
        cvv: "",
        installment_number: 1,
      };
      this.$router.push("/payment-ok");
    } else {
      this.$swal({
        icon: "error",
        title: "Problema no pagamento",
        text:
          data ||
          `
          Não foi possível finalizar seu pagamento. <br/>
          Por favor, verifique se digitou as informações corretamente
          e se recebeu alguma notificação do Banco em seu celular à respeito desta transação.
        `,
      });
      // Pedido não pago[
    }
    this.loadingPayment = false;
  }

  async mounted() {
    if (this.lastInvoice && this.lastInvoice.qtd_parcelas) {
      this.cartaoForm.installment_number = this.lastInvoice.qtd_parcelas;
    }
  }
}
