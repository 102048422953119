import { API_USER_TOKEN, apiUrl } from "@/env";
import axios, { AxiosRequestConfig } from "axios";
import { ICreditCard } from "./interfaces/creditCard";

function axiosConfig(): AxiosRequestConfig {
  return {
    headers: {
      Authorization: `Bearer ${API_USER_TOKEN}`,
    },
    timeout: 30000,
  };
}

const api = {
  async submitPayment(payload: {
    creditCard: ICreditCard;
    couponCode?: string;
  }) {
    const config = axiosConfig();
    config.params = { coupon_code: payload.couponCode };
    return axios.post(
      `${apiUrl}/pre-invoices/payment-credit-card/`,
      payload.creditCard,
      config
    );
  },
  async getFormasPagamento(payload: {
    preInvoiceId?: number;
    company_id?: number;
    couponCode?: string;
    c015_ids?: object;
  }) {
    const resource = payload.preInvoiceId
      ? `${payload.preInvoiceId}/`
      : `${payload.company_id}/${JSON.stringify(payload.c015_ids)}/`;
    const config = axiosConfig();
    config.params = { coupon_code: payload.couponCode };
    return axios.get(
      `${apiUrl}/pre-invoices/formas-pagamento/${resource}`,
      config
    );
  },
  async verifyCoupon(payload: { couponCode: string; companyId: number }) {
    const config = axiosConfig();
    config.params = {
      coupon_code: payload.couponCode,
      company_id: payload.companyId,
    };
    return axios.get(`${apiUrl}/coupons/validate/`, config);
  },
  async getProducts(payload: { franchising_id?: number }) {
    const config = axiosConfig();
    config.params = {
      available: true,
      franchising_id: payload.franchising_id,
      show_material_nivelamento: false,
      allow_invoice_aluno: false,
    };
    return axios.get(`${apiUrl}/products/`, config);
  },
  async getInvoiceAluno(payload: { email: string; password: string }) {
    const config = axiosConfig();
    config.params = payload;
    return axios.get(`${apiUrl}/invoice-alunos/loja/`, config);
  },
};

export default api;
